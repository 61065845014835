import { handleLogin } from 'o365-login';

checkSSO();

function login() {
    const label = document.getElementById('authLabel');
    label?.remove();
    handleLogin();
}

function checkSSO() {
    try {
        if (window.location.pathname !== '/login') { return login(); }
        if(window.location != window.parent.location) { return login(); }
        if (new URLSearchParams(window.location.search).has('sso')) { return login(); }
        fetch('/login/sso/check',
            {
                credentials: 'include',
                method: 'POST',
                signal: AbortSignal.timeout(5000)
            })
            .then((response) => {
                if (response.status !== 200 || response.headers.get("content-type")?.indexOf("application/json") === -1) {
                    return login();
                }
                return response.json().then(json => {
                    if(json.Success || json.success){
                        console.log("Authenticated to CT. Starting SSO flow");
                        window.location.assign('/login/sso/auth' + window.location.search);
                    } else {
                        login();
                    }
                });
                
            }).catch(() => {
                console.log('Failed to SSO, Renderign login');
                login();
            });
    } catch (error) {
        console.log('Failed to SSO, Renderign login', error);
        login();
    }
}